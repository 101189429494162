
import * as Components from "./Themelt"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "themelt"
}

