import React, {useContext} from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import Banner from './banner'
import LogoBlend from './svg/LogoBar'
import Stout from './stout'
import Instagram from './icons/Instagram'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import './LandingPage.scss'
import EmailSubscribeForm from './EmailSubscribeForm'
import './WhatsNew.scss'
import ThemeContext from '../../Context/ThemeContext'
import Form from './Form'



const menuLinks = {
  walnut: [
    {name: "Family Meals TO GO",      link: '/public/walnut-creek/v4-family.pdf'},
    {name: "Meals Instructions",      link: '/public/walnut-creek/bierhaus-family-togo-instructions.pdf'},
    // {name: "Lunch Menu",      link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Lunch%20Menu.pdf'},
    // {name: "Dinner Menu",     link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Dinner%20Menu.pdf'},
    // // {name: "Happy Hour Menu", link: '/public/walnut-creek/v2%20WC%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/walnut-creek/v16%20WC%20Beer%20Menu.pdf'},
    {name: "Beers TO GO",  link: '/public/walnut-creek/v3-Bottle-Menu.pdf'}
  ],
  oakland: [
    // {name: "Daily Menu",  link: '/public/oakland/v6-OAK-Limited-Bierhaus-Food-Menu.pdf'},
    // {name: "Happy Hour Menu",  link: '/public/oakland/L4%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/oakland/v21-OAK-Beer-Menu.pdf'}
  ]
}

const winesale = "https://images.unsplash.com/photo-1524250426644-e24b385c291a?ixlib=rb-1.2.1&auto=format&fit=crop&w=734&q=80"
export default function LandingPage () {
  const {themeName} = useContext(ThemeContext)
  return(
    <div className="landing-page">
      <div className="header">



        <div className="hero-wrapper">

          <div className="hero-banner">

            <div>
              {/* <a href="http://toasttab.com/bierhaus-walnut-creek" className="order-online-button">Order Pickup</a> */}
              <Link to="/menu" className="order-online-button">View Menu</Link>
              {/* <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link> */}
              <p
                style={{
                  color: "white",
                  textShadow: "2px 2px 1px black"
                }}
                >Opening Soon</p>

            </div>
          </div>
          <div className="logo-banner">
            <LogoBlend
              themeName={themeName}
              className="hero-logo"
              ></LogoBlend>


          </div>
        </div>


      </div>

      <div className="content">

        {/* <p
          style={{
            textAlign: 'center',
            margin: '1.5em 0.5em',
            fontWeight: "600",

          }}
          className="notice">NOTICE: We may have to close early afternoon so get in your order now</p> */}

        <div className="content-grid locations">
          <article className="location walnut">
            <div className="location-header">
              <h2 className="location-name">The Heights</h2>

              <p className="subtitle">Food Truck</p>
              <p>We are HALAL</p>
              

              <p className="address">
                <a className="address-link" href="https://www.google.com/maps?q=1207+W+20th+St,+Houston&um=1&ie=UTF-8&sa=X&ved=2ahUKEwik3OTt5dX4AhUOIEQIHa8FDV8Q_AUoAXoECAEQAw">
                1207 W 20th St, Houston
                </a></p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+18323728661">(832) 372-8661</a>
              </p>


              <div className="hours">
                <h3 className="hours-title">Opening Soon</h3>
                {/* <p className="days">All Week</p>
                <p>Sun: 11am to 10pm</p>
                <p>Mon: 3pm to 10pm</p>
                <p>Tue-Thu: 11am to 10pm</p>
                <p>Fri,Sat: 11am to midnight</p> */}
                
              
              </div>
            </div>
          </article>

          {/* <article className="location oak">
            <div className="location-header">
              <h2 className="location-name">2. Food Truck</h2>

              <p className="subtitle">Truck not Halal</p>

              <p className="address">
                Check Instagram
           
              </p>
  
              <div className="hours">
                <h3 className="hours-title">Walk-up ordering</h3>
                <h3 className="hours-title">Tentative Schedule</h3>
           
                <p>***Check <a href="https://www.instagram.com/themeltonwheels/?hl=en">Instagram</a> for service updates</p>
        
              </div>
            </div>
          </article> */}




        </div>


      </div>

      <div className="whats-new">


        <div className="whats-new__content">
          {/* <article className="introduction">
            <h1><span className="made-in">Made in Walnut Creek,</span><br/>San Francisco chef quality meals in your house</h1>
            <p>Welcome to <strong>Bierhaus Kitchen</strong>, a new service from Bierhaus Walnut Creek. We are introducing a super delicious, easy to get on the table menu designed and prepared by a small team of talented chefs from San Francisco.</p>
            <p>We're excited to introduce this creative program and we hope you will consider Bierhaus Kitchen for your next home meal.</p>
          </article> */}

          {/* <article className="full-spread slow cooked">

            <div className="main">
              <img src="https://afag.imgix.net/wok-bar/diy-shelf.jpg?w=900&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Fresh Ingredients</h2>
              </div>
            </div>

          </article>
          <article className="full-spread pizzas cooked">

            <div className="main">
              <img src="https://afag.imgix.net/wok-bar/hotpot.jpg?w=900&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Malatang / Hot Pot</h2>
              </div>
            </div>

          </article>
          <article className="full-spread pizzas cooked">

            <div className="main">
              <img src="https://afag.imgix.net/wok-bar/shaokao-kabob-bbq-2.jpg?w=900&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>BBQ Sticks</h2>
              </div>
            </div>

          </article>
          <article className="full-spread personal">

            <div className="main">
              <img src="https://afag.imgix.net/wok-bar/beef-in-water.jpg?w=900&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Numbing Spicy Beef</h2>
              </div>
            </div>

          </article> */}


        </div>



      </div>

        <Form></Form>

      <div className="menu">
        <div className="menu-header">
          <h2>the MENU</h2>
        </div>

        <AutoMenu width={600}></AutoMenu>

        {/* <div className="beverage-menu-link-wrapper">
          <Link to="/drinks" className="order-online-button">Full Beverage Menu</Link>
        </div> */}
      </div>



      <div className="content">
        <div className="content-grid">
          <div className="email-form">

          </div>


          {/* <div className="social-links">
            <a
              className="social-link"
              href="https://www.instagram.com/bierhausca/"><Instagram></Instagram>Instagram</a>
            <a
              className="social-link"
              href="https://www.facebook.com/bierhausca/"><Facebook></Facebook>Facebook</a>
            <a
              className="social-link"
              href="https://twitter.com/BierhausCA"><Twitter></Twitter>Twitter</a>
          </div> */}
        </div>
      </div>






      <div className="landing-page-footer">
        {/* <Stout></Stout> */}
      </div>
    </div>
  )
}
